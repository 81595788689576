@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --braum-blue: #1e3a8a;
  --braum-light-blue: #3b82f6;
  --braum-gray: #1f2937;
  --braum-light-gray: #e5e7eb;
}

html {
  font-family: 'Inter', sans-serif;
  @apply dark;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--braum-light-gray);
  color: white;
}
body.dark {
  background-color: var(--braum-gray);
  color: white;
}

header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

nav a {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: #5865F2; /* Discord's brand color */
  color: white;
}